<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">

        <div class="login100-form validate-form">
          <span class="login100-form-title p-b-43">
            <img src="@/assets/img/hexagon.png">
          </span>


          <template v-if="state === 'login'">
            <form @submit.prevent="login">
              <span class="login100-form-title p-b-43">
                Вход в ситему
              </span>
              <div class="flex-sb-m w-full p-2 justify-content-center">
                <span class="text-danger d-none" data-name="authError"></span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="text" name="login" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Логин</span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="password" name="password" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Пароль</span>
              </div>

              <div class="flex-sb-m w-full p-t-3 p-b-32 justify-content-end">
                <div class="contact100-form-checkbox d-none">
                  <input class="input-checkbox100" id="ckb1" type="checkbox" name="rememberMe">
                  <label class="label-checkbox100" for="ckb1">
                    Запомнить меня
                  </label>
                </div>

                <div>
                  <a href="#" class="txt1" @click="changeStateToPasswordResetLoginForm">
                    Забыли пароль?
                  </a>
                </div>
              </div>

              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  Вход
                </button>
              </div>
            </form>
          </template>
          <template v-if="state === 'login2fa'">
            <form @submit.prevent="login2fa">
              <span class="login100-form-title p-b-43">
                Вход в ситему
              </span>
              <div class="flex-sb-m w-full p-2 justify-content-center">
                <span class="text-danger d-none" data-name="authError"></span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="text" name="code" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Код из письма</span>
              </div>

              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  Отправить
                </button>
              </div>

              <div class="container-login100-form-btn m-t-16">
                <a href="#" class="txt1" @click="changeStateToLoginForm">
                  Назад
                </a>
              </div>
            </form>
          </template>
          <template v-if="state === 'passwordResetLogin'">
            <form @submit.prevent="resetPasswordSetLogin">
              <span class="login100-form-title p-b-43">
                Восстановление пароля
              </span>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="text" name="login" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Логин</span>
              </div>

              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  Отправить
                </button>
              </div>

              <div class="container-login100-form-btn m-t-16">
                <a href="#" class="txt1" @click="changeStateToLoginForm">
                  Назад
                </a>
              </div>
            </form>
          </template>
          <template v-if="state === 'passwordResetToken'">
            <form @submit.prevent="resetPasswordSetToken">
              <span class="login100-form-title p-b-43">
                Восстановление пароля
              </span>
              <div class="flex-sb-m w-full p-2 justify-content-center">
                <span class="text-danger d-none" data-name="authError"></span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="text" name="token" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Код из письма</span>
              </div>

              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  Отправить
                </button>
              </div>

              <div class="container-login100-form-btn m-t-16">
                <a href="#" class="txt1" @click="changeStateToLoginForm">
                  Назад
                </a>
              </div>
            </form>
          </template>
          <template v-if="state === 'passwordResetSetNew'">
            <form @submit.prevent="resetPasswordSetNew">
              <span class="login100-form-title p-b-43">
                Восстановление пароля
              </span>
              <div class="flex-sb-m w-full p-2 justify-content-center">
                <span class="text-danger d-none" data-name="authError"></span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="password" name="password" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Пароль</span>
              </div>

              <div class="wrap-input100 validate-input" data-validate="Поле обязательно для заполнения">
                <input class="input100" type="password" name="password_confirmation" required>
                <span class="focus-input100"></span>
                <span class="label-input100">Подтверждение пароля</span>
              </div>

              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  Отправить
                </button>
              </div>

              <div class="container-login100-form-btn m-t-16">
                <a href="#" class="txt1" @click="changeStateToLoginForm">
                  Назад
                </a>
              </div>
            </form>
          </template>
        </div>

        <div class="login100-more"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'SignIn',
  components: {

  },
  data() {
    return {
      store: window.Store,
      state: 'login',
      resetPassword: {
        login: null,
        token: null,
      }
    }
  },
  created(){

  },
  methods: {
    changeStateToLoginForm() {
      this.state = 'login'
    },
    changeStateToLogin2FaForm() {
      this.state = 'login2fa'
    },
    changeStateToPasswordResetLoginForm() {
      this.state = 'passwordResetLogin'
    },
    changeStateToPasswordResetTokenForm() {
      this.state = 'passwordResetToken'
    },
    changeStateToPasswordResetSetNewForm() {
      this.state = 'passwordResetSetNew'
    },
    login(event) {
      const formData = new FormData(event.target)
      //const login = formData.get('login')
      const password = formData.get('password')

      formData.set('pass', password)

      fetch(this.store.api.endpoint2 + '/login.php?type=ajax', {
        method: 'POST',
        body: formData
      })
          .then(response => response.json())
          .then(response => {
            if (response.status === 'authed') {
              location.href = this.store.api.endpoint2
            } else if (response.status === 'error') {
              event.target.querySelector('[data-name="authError"]').innerText = 'Неверное имя пользоватля или пароль!'
              event.target.querySelector('[data-name="authError"]').classList.remove('d-none')
            } else if (response.status === '2fa') {
              this.changeStateToLogin2FaForm()
            }
          })
    },
    login2fa(event) {
      const formData = new FormData(event.target)
      fetch(this.store.api.endpoint2 + '/login.php?type=ajax', {
        method: 'POST',
        body: formData
      })
          .then(response => response.json())
          .then(response => {
            if (response.status === 'authed') {
              location.href = this.store.api.endpoint2
            } else if (response.status === 'error') {
              console.log(response)
              event.target.querySelector('[data-name="authError"]').innerText = 'Неверный код одтверждения!'
              event.target.querySelector('[data-name="authError"]').classList.remove('d-none')
            }
          })
    },
    resetPasswordSetLogin(event) {
      const formData = new FormData(event.target)
      this.resetPassword.login = formData.get('login')

      fetch(this.store.api.endpoint + '/passwordReset/init', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: this.resetPassword.login,
        }),
      })
        .then(response => response.json())
        .then(console.log)

      this.changeStateToPasswordResetTokenForm()
    },
    resetPasswordSetToken(event) {
      const formData = new FormData(event.target)
      this.resetPassword.token = formData.get('token')

      fetch(this.store.api.endpoint + '/passwordReset/checkToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: this.resetPassword.login,
          token: this.resetPassword.token
        }),
      })
        .then(response => response.json())
        .then(response => {
          console.log(response)
          if (response.status === 'ok') {
            this.changeStateToPasswordResetSetNewForm()
          } else {
            event.target.querySelector('[data-name="authError"]').innerText = 'Пользователь с такой комбинацией не найден'
            event.target.querySelector('[data-name="authError"]').classList.remove('d-none')
          }
        })
    },
    resetPasswordSetNew(event) {
      const formData = new FormData(event.target)
      const password = formData.get('password')
      const password_confirmation = formData.get('password_confirmation')

      if (password !== password_confirmation) {
        event.target.querySelector('[data-name="authError"]').innerText = 'Пароли не совпадают'
        event.target.querySelector('[data-name="authError"]').classList.remove('d-none')
        return
      }

      fetch(this.store.api.endpoint + '/passwordReset/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: this.resetPassword.login,
          token: this.resetPassword.token,
          password,
          password_confirmation
        }),
      })
          .then(response => response.json())
          .then(response => {
            if (response.status === 'ok') {
              this.changeStateToLoginForm()
            } else {
              event.target.querySelector('[data-name="authError"]').innerText = 'Произошла ошибка! Попробуйте позже'
              event.target.querySelector('[data-name="authError"]').classList.remove('d-none')
            }
          })
    },
  }
}
</script>

<style scoped src="@/assets/login/css/util.css"></style>
<style scoped src="@/assets/login/css/main.css"></style>